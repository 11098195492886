import React, { Component, Fragment, useEffect, useRef, useState } from 'react';
// import Datas from '../../../data/course/item.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
// import { Container, Row, Col } from 'react-bootstrap';

import Pagination from '../../components/Pagination';
import DOMPurify from 'dompurify';
import { PostApi } from '../../services/CommonService2';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Styles } from './styles/course.js';
import HeaderTwo from '../../components/HeaderTwo';
import StProgramSidebar from './components/StProgramSidebar.js';
import { prettyDOM } from '@testing-library/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginModal from '../../components/LoginModal.js';
import FooterTwo from '../../components/FooterTwo.js';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const StuProgramGrid = () => {
    const didMountRef = useRef(false);
    const location = useLocation();

    const [allStPg, setAllStPg] = useState([])
    const [allStPg2, setAllStPg2] = useState([])
    const [allTypes, setAllTypes] = useState([])
    const [updatedTypes, setUpdatedTypes] = useState([])
    const [categorySelect, setCategorySelect] = useState([])
    const [totalRecords, setTotalRecords] = useState(null)
    const [searchKey, setSearchKey] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredTypes,setFilteredTypes]=useState([])
    const [modalUp, setModalUp] = useState(false)
    const [showError,setShowError]=useState(false)
    const history=useHistory();
    const [allSearch, setAllSearch]=useState(false)
    useEffect(() => {
        getData();
        localStorage.removeItem('previewtype');
    }, [])


    useEffect(() => {
        let arr = allTypes.map((item) => {
            return {
                ...item,
                check: false
            }
        })
        setUpdatedTypes(arr)

    }, [allTypes])



    const getData = async () => {
        // localStorage.removeItem('edutype');
        // localStorage.setItem('edutype', JSON.stringify('ITPROGRAMING'));
        localStorage.removeItem('edutype');
        localStorage.setItem('edutype', JSON.stringify('STUDENTPROGRAMMING'));

        const reqparam2 = {
            pageno: '-1',
            query: { status: 'Active', edutypeid: location.state.id }

            // query:{status:'Active',edutypeid :item.id,displayposition:'1'}
        }
        let categoriesResponse = await PostApi(reqparam2, 'INDIVIDUALEDUPROGRAM');
        setAllTypes(categoriesResponse.data)
    }

    useEffect(()=>{
        var typesOnly=allTypes.map((item)=>{
            return item.id
        })
        // console.log("allTypesallTypesallTypes",typesOnly);
        setFilteredTypes(typesOnly)

        const getPrograms=async()=>{
            const reqparams = {
                pageno: 1,
                query: { status: 'Active' },
                optimize: true
            }
            reqparams.query.program = { $elemMatch: { "value": { $in: typesOnly } } };
            let response = await PostApi(reqparams, 'STUDENTPROGRAM');
            // console.log("all STUDENTPROGRAM",response?.data?.docs);
            setAllStPg(response?.data?.docs)
            setAllStPg2(response?.data)
            setTotalRecords(response?.data?.totalDocs)
            // console.log("total mate",response.data);
        }

        getPrograms()

    },[allTypes])

    async function ctgSearch(e, data) {
        const { checked } = e.target

        if (checked) {
            setSearchKey("")

            if (categorySelect.length == 0) {
                setCategorySelect([data])
            }
            else {
                setCategorySelect((prevState) => {
                    return [...prevState, data]
                })
            }

        }
        else {
            setCategorySelect(categorySelect.filter((item) => item != data))
        }
    }

    useEffect(() => {

        if (didMountRef.current) {
            const categorySelectCheck = categorySelect.filter(t => t != null);
            const ctgbasedsearch = async () => {
                const reqparams3 = {
                    pageno: 1,
                    query: { status: 'Active' },
                    optimize: true
                }
                reqparams3.query.program = { $elemMatch: { "value": { $in: categorySelectCheck } } };
                let response = await PostApi(reqparams3, 'STUDENTPROGRAM');
                setAllStPg(response.data.docs)
                setTotalRecords(response.data.totalDocs)
                setCurrentPage(1)
                const updatedTypesWithCheck = updatedTypes.map((type) => ({
                    ...type,
                    check: categorySelect.some((categoryId) => categoryId === type.id),
                }));
                setUpdatedTypes(updatedTypesWithCheck)

            }

            if (categorySelectCheck.length != 0) {
                ctgbasedsearch()
            }
            else {
                if (searchKey == '') {
                    setAllStPg(allStPg2.docs)
                    setTotalRecords(allStPg2.totalDocs)

                    const updatedTypesWithCheck = updatedTypes.map((type) => ({
                        ...type,
                        check: false,
                    }));

                    setUpdatedTypes(updatedTypesWithCheck)
                }
            }
        }
        else {
            didMountRef.current = true;
        }
    }, [categorySelect])


    //search function

    const searchData = async (keySearch) => {

        setSearchKey(keySearch)

        // console.log("key", keySearch);

        const reqparams1 = {
            pageno: 1,
            query: { status: 'Active', name: { $regex: keySearch, $options: "i" } },
            optimize: true
        }
        reqparams1.query.program = { $elemMatch: { "value": { $in: filteredTypes } } };
        // reqparams1.query.name = { $regex: keySearch, $options: "i" }
        let response = await PostApi(reqparams1, 'STUDENTPROGRAM');
        // console.log("search dataa",response.data.docs);
        // console.log("search dataa total",response.data.total);
        if(response.data.totalDocs==0){
            setShowError(true)
        }
        else{
            setShowError(false)
        }
        setAllStPg(response.data.docs)
        setTotalRecords(response.data.totalDocs)
        setCategorySelect([])
        setCurrentPage(1)
        const updatedTypesWithCheck = updatedTypes.map((type) => ({
            ...type,
            check: false,
        }));

        setUpdatedTypes(updatedTypesWithCheck)


    }


    //useEffect when current page changes

    useEffect(() => {

        const setData = async () => {
            const reqparams1 = {
                pageno: currentPage,
                query: { status: 'Active' },
                optimize: true
            }
            // console.log("categorySelect value",categorySelect);
            if (categorySelect.length != 0) {
                reqparams1.query.program = { $elemMatch: { "value": { $in: categorySelect } } };
            }
            else if (searchKey != "") {
                reqparams1.query.name = { $regex: searchKey, $options: "i" }
            }

            const dataResponse2 = await PostApi(reqparams1, 'STUDENTPROGRAM'); //programs based on prog type 
            // console.log("All STUDENTPROGRAM page change", dataResponse2.data.docs);
            setAllStPg(dataResponse2.data.docs)
        }

        setData()

    }, [currentPage])

    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const npage = Math.ceil(totalRecords / recordsPerPage)
    const records = allStPg.slice(firstIndex, lastIndex)
    const numbers = [...Array(npage + 1).keys()].slice(1)


    const prePage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }


    }
    const changeCPage = (id) => {
        setCurrentPage(id)

    }
    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)

        }

    }


       //login validate
       const loginValidate = (data) => {
        var tntId= JSON.parse(localStorage.getItem('tID'))

        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        if (userdata == null) {
            setModalUp(true)
            // e.preventDefault()

            toast.info('login to check course details', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        else{
            history.push({
                pathname:"/lmspage2",
                state:data
            })
        }
    }
    const openModal = () => {
        setModalUp(false)
    }

    const logIn = () => {

    }


    return (
        <div className="main-wrapper course-page">

            <HeaderTwo />
            
            <Styles>
                <section className="course-list-area">
                    <Container>
                        <Row>
                            {/* {console.log("all st prog bruv",allStPg)} */}
                            <Col lg="3" md="4" sm="5">
                                <StProgramSidebar types={updatedTypes} ctgFn={ctgSearch}
                                    dispAllPg={""} searchData={searchData}
                                />
                            </Col>
                            <Col lg="9" md="8" sm="7">
                                <div className="course-items2">
                                    <Row>
                                        <Fragment>


                                            {
                                                allStPg?.map((data, i) => {

                                                    let imageid ='https://visualpathedu.com/static/media/4th-year.15b862d606512e3257c0.jpg';
                                                    if(data?.image){
                                                        imageid =data.image;
                                                     }else if(data?.image_url){
                                                         imageid =data.image_url;
                                                     }
                                                     else if(data?.imageurl){
                                                           imageid =data.imageurl? data.imageurl:"";
                                                      }


                                               return  <Col md="12" key={i}>

                                                        <div className="course-item d-flex" onClick={()=>loginValidate(data)}>
                                                            <div className="course-image-box">
                                                                <div className="course-image">
                                                                    <div className="author-img ">
                                                                        <div className="img">
                                                                            {/* <img src={data.image} alt="" />
                                                            <Link to={process.env.PUBLIC_URL + data.courseLink}>
                                                                <img src={data.image} alt="" />
                                                            </Link> */}
                                                                        </div>
                                                                        {/* <div className="title">
                                                        <p>{data.price}</p>
                                                        <span>{data.price}</span>
                                                    </div> */}
                                                                    </div>

                                                                    <img src={imageid} className="course-image" ></img>
                                                                    {/* <div className="sale-div">
                                                    <p>Sale </p>
                                                </div>
                                                <div className="course-price">
                                                    <p>Rs.{data.sale_price} <span  className='price-div'  >Rs.{data.price}</span> </p>
                                                </div> */}

                                                                </div>
                                                            </div>
                                                            <div className="course-content">
                                                                <h6 className="heading">{data.name}</h6>
                                                                <div className="rating">
                                                                    <ul className="list-unstyled list-inline">
                                                                    {data?.duration && <p className='duration2' ><i class="fa-regular fa-clock projects-icon"></i>duration:{data?.duration}</p>}
                                                                    </ul>
                                                                </div>

                                                                <p className="desc2">{data.description?.slice(0, 100)}</p>
                                                                <button className="details-btn" >View Details</button>

                                                            </div>

                                                        </div>



                                                    </Col>
                                                })
                                            }

                                            {showError && <Skeleton height={180} count={4} /> }

                                            {/* <Pagination /> */}

                                           {!showError && <Col md="12" className="text-center">
                                                <div className='mainpagination'>
                                                    <ul className='pagination'>
                                                        <li className='page-item'>
                                                            <label className=' btn btn-outline-success page-link' onClick={prePage}><i class="fa-solid fa-angles-left"></i></label>

                                                        </li>
                                                        {numbers.map((n, i) => (
                                                            <li className={`page-item ${currentPage === n ? 'active' : ""}`} key={i}>
                                                                <label className='btn btn-outline-success page-link' onClick={() => changeCPage(n)}>{n}</label>

                                                            </li>

                                                        ))}

                                                        <li className='page-item'>
                                                            <label className='btn btn-outline-success page-link' onClick={nextPage}><i class="fa-solid fa-angles-right"></i></label>

                                                        </li>


                                                    </ul>
                                                </div>


                                            </Col>}

                                            {/* <Col md="12" className="text-center">
                                                <Pagination />
                                            </Col> */}

                                        </Fragment>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    {modalUp && <LoginModal onClose={openModal} login={logIn}></LoginModal>}
                </section>
            </Styles>

            {/* Footer 2 */}
            <FooterTwo />
            


        </div>
    )

}
export default StuProgramGrid


