import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col, Dropdown, Image, Modal } from 'react-bootstrap';
import { Tab, Nav } from 'react-bootstrap';
import { toast } from 'react-toastify';

import MobileMenu from './common/MobileMenu';
import { Styles } from "./styles/headerTwo.js";
import { TabStyles } from '../pages/faq/styles/tab.js'
import { GetApi, PostApi } from '../services/CommonService2.js'
import {
    Button,

} from "react-bootstrap";
// import { Dropdown } from 'react-bootstrap';
import LoginModal from './LoginModal.js';
import Register from '../pages/account/Register.js';
import Student from './offlineEnroll/Student.js';
import Fresher from './offlineEnroll/Fresher.js';
import Employee from './offlineEnroll/Employee.js';
import Notifications from './Notifications.js';
// import Dropdown from 'react-bootstrap/Dropdown';
// import DropdownButton from 'react-bootstrap/DropdownButton';

export default function HeaderTwo() {
    const [logo, setLogo] = useState([])
    const [headerTabs, setHeaderTabs] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const [user, setUser] = useState("")
    const [userMobile, setUserMobile] = useState("")

    const [toggleUser, setToggleUser] = useState(false)
    const [postModal, setPostModal] = useState(false);
    const [userProfile, setUserProfile] = useState("");
    const [courses, setCourses] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [notificationmodal, setNotificationmodal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [tenantdata, setTenantData] = useState({});
    const [loginModal, setLoginModal] = useState(false);
    const [modalUp, setModalUp] = useState(false);
    const [selectedState, setSelectedState] = useState([])
    const [couponPurchase, setCouponPurchase] = useState(false)
    const [tenantId, setTenantId] = useState('')
    const [userData, setUserData] = useState('')




    const [offlineModalShow, setOfflineModalShow] = useState(false)
    const [isOpenFlag, setIsOpenFlag] = useState(false);

    const handleMouseEnterFlag = () => {
        setIsOpenFlag(true);
    };

    const handleMouseLeaveFlag = () => {
        setIsOpenFlag(false);
    };
    // var headerURL = ""

    useEffect(() => {

        setToggleUser(prevState => !prevState)
        loaddata()
    }, [])



    const openRegisterModal = () => {
        setPostModal(true)
        // onClose()
    }
    const closemodal = () => {
        setPostModal(false)
        setModalShow(true)

    }




    useEffect(() => {
        getLogo()
        headerTypes()
        getTrainingRegistrationData()

        // console.log("local storage data", JSON.parse(localStorage.getItem('userdata')));

        // localStorage.removeItem('userdata')
        var tntId = JSON.parse(localStorage.getItem('tID'))

        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))

        if (userdata == null) {
            setUser("")
        }
        else if (userdata.accessToken) {
            setUser(userdata.user.name)
            setUserProfile(userdata.user.image)
            setUserMobile(userdata.user.mobileno)
            setSelectedState(userdata.user.stateid)

        }

    }, [toggleUser])



    const getLogo = async () => {
        const action = "INTRO"
        const reqparam = {
            pageno: '-1',
            query: { status: 'Active' }
        }

        const ResponseData = await GetApi(reqparam, action);
        // console.log("intro response", ResponseData);
        setLogo(ResponseData?.data);


        const reqparam2 = {
            pageno: '-1',
            query: {}
        }

        const ResponseData1 = await GetApi(reqparam2, 'TENANT');
        // console.log("response tenant data", ResponseData1.data[0]);
        // alert()
        setTenantData(ResponseData1.data[0])
        var tntId = JSON.parse(localStorage.getItem('tID'))
        localStorage.setItem(`tenantdata${tntId}`, JSON.stringify(ResponseData1?.data[0]));

        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }

        // adding favcon
        link.href = ResponseData?.data[0]?.smallicon;
        console.log(ResponseData1, "check tenant name");
        //adding document title
        document.title = ResponseData1?.data[0]?.name



    }

    const headerTypes = async () => {
        let reqparams = ''
        var tntId = JSON.parse(localStorage.getItem('tID'))
        setTenantId(tntId)
        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        setUserData(userdata)
        if (userdata?.user?.subapplicationid && userdata != null) {
            reqparams = {
                pageno: '-1',
                query: {
                    status: 'Active', area: {
                        $elemMatch: { "value": userdata?.user?.subapplicationid }
                    }
                },

            }

        } else {
            reqparams = {
                pageno: '-1',
                query: { status: 'Active' },

            }

        }

        // console.log("header reqparams", reqparams);

        const dataResponse = await PostApi(reqparams, 'VPTTYPES');

        console.log("VPT headers", dataResponse.data);
        setHeaderTabs(dataResponse?.data)
    }


    const openModal = () => {
        setModalOpen((prevState) => !prevState);
        // setModalShow((prevState) => !prevState);
    };

    const logIn = () => {
        setToggleUser(prevState => !prevState)

    }
    const logOut = async (e) => {
        // await removeToken()
        e.preventDefault()
        setShowModal(true)



        // openModal()
        // setToggleUser(prevState=>!prevState)

    }

    //logout confirmation

    const exitmodal = () => {

        setShowModal(false)

    }

    const logoutConfirmed = async () => {

        // console.log("userMobile",userMobile);

        const reqparams = {
            mobileno: userMobile
            // mobileno:"9391645909"

        }
        const clearsessionrespone = await PostApi(reqparams, 'LOGOUT');
        // console.log("clearsessionrespone", clearsessionrespone);
        var tntId = JSON.parse(localStorage.getItem('tID'))

        localStorage.removeItem(`userdata${tntId}`)

        logIn()

        setShowModal(false)

        window.location.href = '/'
    }


    const getTrainingRegistrationData = async () => {
        const reqparams = {
            pageno: '-1',
            query: { status: 'Active' }
        }
        const courseslistResp = await PostApi(reqparams, 'TRAININGCOURSES');

        // console.log("TRAININGCOURSES datta", courseslistResp.data);

        let count = 0;
        const rowdat = [];
        await courseslistResp.data.reduce(async (promise, res) => {
            await promise;
            const opts = {
                key: res.id,
                value: res.name
            }
            rowdat.push(opts);
            if (count == courseslistResp.data.length - 1) {


                setCourses(rowdat)
            }
            count++;
        }, Promise.resolve());


    }

    const notifymodal = async () => {
        setNotificationmodal(true)

    }
    const closeNotifyModal = async () => {
        setNotificationmodal(false)
    }

    const clsDeleteModal = async () => {
        setDeleteModal(false)
    }
    const deleteAccount = async () => {

        setDeleteModal(true)

    }
    const deactivateConfirmed = async () => {
        const query = {
            userid: '',
            tenantid: ''
        }
        const deleteResp = await PostApi(query, "DELETEACCOUNT");
        // console.log("deleteResp",deleteResp);
        if (deleteResp.message == 'SUCCESS') {
            var tntId = JSON.parse(localStorage.getItem('tID'))

            localStorage.removeItem(`userdata${tntId}`)
            logIn()
            setDeleteModal(false)
            // history.push(`${"/"}`)
            window.location.href = '/'

        }
        else {
            toast.error("Failed to delete account", {
                position: "center",
                autoClose: 5000,

            })
        }

    }
    const AcademicValidate = async (e) => {
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        if (userdata == null) {
            setModalUp(true)
            e.preventDefault()

            toast.info('login to check course details', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const closeModal = async () => {
        setModalUp(false)

    }
    const signIn = async () => {

    }

    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
            // size="lg"
            // aria-labelledby="contained-modal-title-vcenter"
            // centered
            >
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter" style={{ marginLeft: "auto" }}>
                        Enroll for offline/online class
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body >
                    <TabStyles >
                        <div className="main-wrapper faq-page">
                            <section className="faq-area" style={{ paddingTop: "2%" }}>
                                <Container>
                                    <Row>
                                        <Col md="12">
                                            <Tab.Container defaultActiveKey="student">
                                                <Nav className="justify-content-center">
                                                    <Nav.Item><Nav.Link eventKey="student">Student </Nav.Link></Nav.Item>
                                                    <Nav.Item><Nav.Link eventKey="fresher">Fresher</Nav.Link></Nav.Item>
                                                    <Nav.Item><Nav.Link eventKey="employee">Employee</Nav.Link></Nav.Item>
                                                </Nav>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="student">
                                                        <Row>

                                                            <Student dataprop={courses} />

                                                        </Row>
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="fresher">
                                                        <Row>

                                                            <Fresher dataprop={courses} />

                                                        </Row>
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="employee">
                                                        <Row>

                                                            <Employee dataprop={courses} />

                                                        </Row>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        </div>
                    </TabStyles>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
                {/* <ToastContainer /> */}
            </Modal>
        );
    }


    const loginValidate = async () => {
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        if (userdata == null) {
            setModalOpen((prevState) => !prevState)


            toast.info('login to register for Offline Enrollment', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const loaddata = async () => {
          var tntId= JSON.parse(localStorage.getItem('tID'))
          
          const sessiondetails =localStorage.getItem(`userdata${tntId}`);
          if (sessiondetails != null) {
      
            const userdata = JSON.parse(sessiondetails);
      
             const reqparam ={
              pageno:'-1',
              ptype:'GETUSER',
              query:{_id:userdata.user.id}
             }
             const resDat = await PostApi(reqparam,'USERPROJECTS');
             if(resDat?.data?.couponpurchase == "No"){
                setCouponPurchase(true);
                
             }
            
          }
        }


    return (
        <Styles>
            {/* Logo Area 2 */}
            <section className="logo-area2">
                <Row className='align-items-center'>
                    <Col md="2" style={{ zIndex: "99999" }}>

                        <Link to={"/"}><img src={`${logo[0] && logo[0]?.largeicon}`} alt="" style={{ height: '60px' }} /></Link>

                    </Col>
                    <Col md="10">
                        <div className="menu-box d-flex justify-content-end">
                            <ul className="nav menu-nav" style={{ display: "flex", alignItems: "center" }}>
                                {/* <li className="nav-item dropdown active"> */}
                                {/* <Link className="nav-btn btn btn-primary" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Home </Link> */}
                                {/* <NavItem as="li">
                                            <Link
                                                to="/home-two"
                                                className="nav-btn btn"
                                                id="header-nav-btn All-Courses"
                                            >
                                                <Button className="nav-btn btn " style={{ maxHeight: "40px" }}>
                                                    Home
                                                </Button >
                                            </Link>
                                        </NavItem> */}
                                {/* <ul className="dropdown list-unstyled">
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/"}>Home Style 1</Link></li>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/home-two"}>Home Style 2</Link></li>
                                        </ul> */}
                                {/* </li> */}

                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to={'/'} data-toggle="dropdown" >Home</Link>
                                </li>
                                {couponPurchase && headerTabs?.map((tab,ind)=>{
                                    return(
                                        <>{tab.name==="Coding Practice" && <li className="nav-item dropdown" >
                                            <Link className="nav-link dropdown-toggle" to={'/codeeditor'} data-toggle="dropdown" >Coding Practice</Link>
                                        </li> }
                                        </>
                                    )

                                })}
{/* 
                                {tenantdata.tenantid != "80b42fdf" && <li className="nav-item dropdown" >
                                    <Link className="nav-link dropdown-toggle" to={'/codeeditor'} data-toggle="dropdown" >Coding Practice</Link>
                                </li>} */}

                                {headerTabs?.length > 0 && <li className="nav-item dropdown" onMouseEnter={handleMouseEnterFlag} onMouseLeave={handleMouseLeaveFlag} style={{ height: "40px" }}>
                                    <Dropdown show={isOpenFlag} >
                                        <Dropdown.Toggle variant='light' style={{ fontSize: "14px", fontWeight: "500", marginTop: "3px" }}>
                                            ALL PROGRAMS
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {headerTabs?.map((tab, ind) => {
                                                let url = ""
                                                if (tab.name === 'Students') {
                                                    url = '/allstuprogram-list'
                                                }
                                                if (tab.name === 'Academic Subjects') {
                                                    // AcademicValidate()
                                                    // url = '/subjects'
                                                }

                                                if (tab.name === 'Technical Courses') {
                                                    url = '/course-list'
                                                }
                                                if (tab.name === 'Employability skills') {
                                                    url = '/crt-program'
                                                }
                                                if (tab.name === 'Professionals') {
                                                    url = '/course-list'
                                                }
                                                if (tab.name === 'Freshers') {
                                                    url = '/course-list'
                                                }
                                                return (<>
                                                    {tab.name === 'Academic Subjects' &&  <Dropdown.Item key={ind}>

                                                        {(tenantId !="2b7b1a31" || userData?.user?.year =="4") &&<Link className='dropdown-item fs-5'
                                                            onClick={AcademicValidate} to={{ pathname: '/subjects', state: { data: selectedState } }} data-toggle="dropdown" key={ind}>{tab.name} </Link>}

                                                    </Dropdown.Item >}

                                                    {(tab.name === 'Technical Courses' || tab.name === 'Professionals' || tab.name === 'Freshers') && <Dropdown.Item key={ind}>

                                                        <Link className='dropdown-item fs-5'
                                                            onClick={AcademicValidate} to={{ pathname: '/course-list' }} data-toggle="dropdown" key={ind}>{tab.name} </Link>

                                                    </Dropdown.Item >}
                                                    {tab.name === 'Employability skills' && <Dropdown.Item key={ind}>

                                                    {(tenantId !="2b7b1a31" || userData?.user?.year =="4") && <Link className='dropdown-item fs-5'
                                                            onClick={AcademicValidate} to={{ pathname: '/crt-program' }} data-toggle="dropdown" key={ind}>{tab.name} </Link>}

                                                    </Dropdown.Item >}
                                                    {tab.name === 'Students' && <Dropdown.Item key={ind}>

                                                        <Link className='dropdown-item fs-5'
                                                            onClick={AcademicValidate} to={{ pathname: '/allstuprogram-list' }} data-toggle="dropdown" key={ind}>{tab.name} </Link>

                                                    </Dropdown.Item >}
                                                </>)
                                            })}


                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>}
                                {user && tenantdata.tenantid != "80b42fdf" && <li className="nav-item dropdown" >
                                    <Link className="nav-link dropdown-toggle" to={'/MyCourses'} data-toggle="dropdown" >My Courses</Link>
                                </li>}

                                {tenantdata?.isofflineenrollment &&


                                    <li className="nav-item dropdown" >
                                        <button type="button" className="btn btn-primary btn-lg signin tooltip1" onClick={() => user ? setOfflineModalShow(true) : loginValidate()} >Training Registration<span className="tooltiptext1">Registration for free online training</span> </button>
                                        {/* <div class="tooltip">Hover over me
                                    <span class="tooltiptext">Tooltip text</span>
                                </div> */}

                                    </li>

                                }

                                {/* {user && tenantdata?.isofflineenrollment &&


                                    <li className="nav-item dropdown" >
                                        <button type="button" className="btn btn-primary btn-lg signin" onClick={() => setOfflineModalShow(true)} > Offline Enroll  </button>

                                    </li>

                                } */}
                                {/* <button onClick={logoutConfirmed}>logout test</button> */}

                                {/* <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/course-list"} ">Technical Courses</Link>
                                      
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="/course-grid" data-toggle="dropdown">CRT </Link>
                                        <ul className="dropdown list-unstyled">
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/instructor"}>Instructors</Link></li>
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/instructor-details"}>Instructor Details</Link></li>
                                        </ul>
                                    </li> */}
                                {/* <div className="search-box">
                                        <Search />
                                    </div> */}


                                {/* <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="/about" data-toggle="dropdown">About US </Link>

                                    </li>

                                    <li className="nav-item dropdown ">
                                        <Link className="nav-link dropdown-toggle" to="/contact">Contact </Link>

                                    </li> */}
                                {!user && <li className="nav-item dropdown ms-3" onClick={openModal}>
                                    {/* <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL +"/login"} >Signin </Link> */}

                                    <button className="btn btn-primary btn-lg signin" > Signin </button>
                                </li>}


                                {modalOpen && <LoginModal onClose={openModal} show={modalShow} Hide={modalOpen} login={logIn} />}

                                {!user && <li className="nav-item dropdown">
                                    <button className="btn btn-primary btn-lg" onClick={openRegisterModal}> Sign Up </button>

                                </li>}
                                {user && <li>
                                    <Dropdown className='mx-4'>
                                        <Dropdown.Toggle id="dropdown-basic" className='login-div' style={{ backgroundColor: "white" }}>
                                            {/* {user} */}
                                            {/* {console.log("user profile value",userProfile)} */}
                                            <Image className='d-inline' src={userProfile ? `data:image/jpeg;base64,${userProfile}` : "https://tse3.mm.bing.net/th?id=OIP._lZHLa4KljgIGbchN7qRTgHaHa&pid=Api&P=0&h=180"} roundedCircle height="40px" width="40px" />
                                            <span className='d-inline' style={{ color: "black", marginLeft: "10%" }}>{user}</span>

                                        </Dropdown.Toggle>

                                        <Dropdown.Menu >
                                            {/* <Dropdown.Item href="#/action-1">{user}</Dropdown.Item> */}
                                            {tenantdata.tenantid != "80b42fdf" && <Link to={`${"/about"}`}>    <Dropdown.Item href="#/action-2" className='profile profile2'>My Profile</Dropdown.Item> </Link>}

                                            <Link to={`${"/changepassword"}`}>    <Dropdown.Item href="#/action-2" className='profile profile2'>Change Password</Dropdown.Item> </Link>
                                            <Dropdown.Item className='profile' href="#" onClick={deleteAccount}>Deactivate account</Dropdown.Item>
                                            <Dropdown.Item className='profile' href="#" onClick={logOut}>Log out</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>}

                                {user && <div class="col"> <li onClick={notifymodal} className='notify'><i className="fa-solid fa-bell fa-2x"></i></li></div>}

                            </ul>
                            {/* <div className="search-box">
                                        <Search />
                                    </div> */}



                            {/* <div className="apply-btn">
                                        <Link to={process.env.PUBLIC_URL + "/registration"}><i className="las la-clipboard-list"></i>Apply Now</Link>
                                    </div> */}
                        </div>
                    </Col>
                </Row>
            </section>

            {/* Sticky Menu */}
            {/* <StickyMenu /> */}

            {/* Mobile Menu */}
            <MobileMenu />


            <Modal size="l" show={postModal} >
                <div className="" role="document">
                    <div className="">
                        <form >
                            <div className="modal-header">

                                <button type="button" className="btn-close" onClick={closemodal} data-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">
                                <Register closemodal={closemodal} loginmodal={openModal} />
                            </div>
                        </form>

                    </div>
                </div>
            </Modal>

            {/* offlineEnroll modal */}

            <MyVerticallyCenteredModal
                show={offlineModalShow}
                onHide={() => setOfflineModalShow(false)}
            />

            {/* logout confirmation model */}


            <Modal size="l" show={showModal} >
                <div className="" role="document">
                    <div className="">
                        <form >
                            <div className="modal-header">

                                <button type="button" className="btn-close" onClick={exitmodal} data-dismiss="modal"></button>
                            </div>
                            <div className="modal-body text-center">
                                <h5>Are you sure you want to logout ?</h5>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={logoutConfirmed}>ok</button>
                                <button type="button" className="btn btn-secondary" onClick={exitmodal} data-dismiss="modal">cancel</button>
                            </div>
                        </form>

                    </div>
                </div>
            </Modal>

            <Modal size="lg" show={notificationmodal} >
                <div className="" role="document">
                    <div className="">
                        <form >
                            <div className="modal-header">

                                <button type="button" className="btn-close" onClick={closeNotifyModal} data-dismiss="modal"></button>
                            </div>
                            <div>
                                <Notifications />
                            </div>


                        </form>

                    </div>
                </div>
            </Modal>

            <Modal size="l" show={deleteModal} >
                <div className="" role="document">
                    <div className="">
                        <form >
                            <div className="modal-header">

                                <button type="button" className="btn-close" onClick={clsDeleteModal} data-dismiss="modal"></button>
                            </div>
                            <div className="modal-body text-center">
                                <h5>Are you sure you want to deactivate your account , Once you deactivate, you cannot login again  ?</h5>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={deactivateConfirmed}>ok</button>
                                <button type="button" className="btn btn-secondary" onClick={clsDeleteModal} data-dismiss="modal">cancel</button>
                            </div>
                        </form>

                    </div>
                </div>
            </Modal>

            {modalUp && <LoginModal onClose={closeModal} login={signIn}></LoginModal>}


        </Styles>
    )
}
