import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import FooterTwo from '../../components/FooterTwo';
import HeaderTwo from '../../components/HeaderTwo';
import { PostApi } from '../../services/CommonService2';
import CourseItemList from './components/CourseItemsList';
import CourseSidebar from './components/CourseSidebar';
import { Styles } from './styles/course.js';
import Header3 from '../../home3components/Header3.jsx';
import Footer3 from '../../home3components/Footer3.js';



const CourseList = () => {
    const [courseList, setCourseList] = useState([]);
    const [course, setCourse] = useState([]);
    const [category, setCategory] = useState([]);
    const [updatedCategory, setUpdatedCategory] = useState([])
    const [tenantId, setTenantId] = useState('')




    const didMountRef = useRef(false);
    const didMountRef2 = useRef(false);

    const [categorySelect, setCategorySelect] = useState([])

    const [searchKey, setSearchKey] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(1)
    const [totalRecords, setTotalRecords] = useState(null)

    const location = useLocation();


    useEffect(() => {
        //   if(location?.state?.name !="") {
        //     searchBar(location?.state?.name)
        //   }else{
        //     searchBar("")
        //     setSearchKey("")
        //   }
        var tntId = JSON.parse(localStorage.getItem('tID'))
        if (course.length != 0 && tntId != '2b7b1a31') {

            setCategorySelect([])
        }
        setSearchKey("")


    }, [location])




    useEffect(() => {
        getCourses();
        getCategory();
        getAllCourse();
    }, [])

    useEffect(() => {

        let arr = category.map((item) => {
            return {
                ...item,
                check: false
            }
        })

        // console.log("arr mate", arr);
        setUpdatedCategory(arr)
        // setUpdatedCategory2(arr)

    }, [category])





    const getCourses = async () => {

        var tntId = JSON.parse(localStorage.getItem('tID'))
        setTenantId(tntId)
        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        // console.log("userdata", userdata);

        var reqparam = {
        }

        if (tntId === "2b7b1a31") {
            if (userdata?.user.subapplicationid) {
                if (location?.state?.name != undefined) {
                  
                    
                    reqparam = {
                        pageno: 1,
                        query: { status: 'Active', branch: { $elemMatch: { "value": userdata?.user.studentbranch } },
                        year: { $elemMatch: { "value": userdata?.user.year } } },
                        usertype: 'APP',
                        tenantid: '',
                        optimize: true,
                        userid: ""
                    }
                    reqparam.query.name = { $regex: location.state.name, $options: "i" }
                }
                else {
                    reqparam = {
                        pageno: 1,
                        query: {
                            status: 'Active',
                            area: {$elemMatch: { "value": userdata?.user.subapplicationid }},
                                branch: { $elemMatch: { "value": userdata?.user.studentbranch }},
                                year: { $elemMatch: { "value": userdata?.user.year } } 
                            
                        },
                        usertype: 'APP',
                        tenantid: '',
                        optimize: true,
                        userid: ""
                    }
                }

            } else {

                if (location?.state?.name != undefined) {
                    reqparam = {
                        pageno: 1,
                        query: { status: 'Active', branch: { $elemMatch: { "value": userdata?.user.studentbranch } },
                        year: { $elemMatch: { "value": userdata?.user.year } } },
                        usertype: 'APP',
                        tenantid: '',
                        optimize: true,
                        userid: ""
                    }
                    reqparam.query.name = { $regex: location.state.name, $options: "i" }
                }
                else {
                    reqparam = {
                        pageno: 1,
                        query: {
                            status: 'Active',
                            branch: { $elemMatch: { "value": userdata?.user.studentbranch }},
                            year: { $elemMatch: { "value": userdata?.user.year } }
                        },
                        usertype: 'APP',
                        tenantid: '',
                        optimize: true,
                        userid: ""
                    }
                }
            }


        } else {

            if (userdata?.user.subapplicationid) {
                if (location?.state?.name != undefined) {
                    reqparam = {
                        pageno: 1,
                        query: { status: 'Active' },
                        usertype: 'APP',
                        tenantid: '',
                        optimize: true,
                        userid: ""
                    }
                    reqparam.query.name = { $regex: location.state.name, $options: "i" }
                }
                else {
                    reqparam = {
                        pageno: 1,
                        query: {
                            status: 'Active',
                            area: { $elemMatch: { "value": userdata?.user.subapplicationid } }
                        },
                        usertype: 'APP',
                        tenantid: '',
                        optimize: true,
                        userid: ""
                    }
                }

            } else {

                if (location?.state?.name != undefined) {
                    reqparam = {
                        pageno: 1,
                        query: { status: 'Active' },
                        usertype: 'APP',
                        tenantid: '',
                        optimize: true,
                        userid: ""
                    }
                    reqparam.query.name = { $regex: location.state.name, $options: "i" }
                }
                else {
                    reqparam = {
                        pageno: 1,
                        query: { status: 'Active' },
                        usertype: 'APP',
                        tenantid: '',
                        optimize: true,
                        userid: ""
                    }
                }
            }
        }
        const action = "ITPROGRAMS"
        // console.log(reqparam.query.name ,"chek the name of the Tcs program")
        const ResponseData = await PostApi(reqparam, action);
        console.log("ResponseData", ResponseData);


        setCourseList(ResponseData.data);
        setTotalRecords(ResponseData.total)

    }

    const getAllCourse = async () => {
        const reqparam = {
            pageno: currentPage,
            // page:currentPage,
            query: { status: 'Active' },
            usertype: 'APP',
            tenantid: '',
            optimize: true,
            userid: ""
        }
        const action = "ITPROGRAMS"
        const ResponseData = await PostApi(reqparam, action);
        // console.log(ResponseData, "ResponseData initial courses")

        setCourse(ResponseData.data)
        setCount(ResponseData)
    }

    const getCategory = async () => {
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        let reqparam = '';
        if (userdata?.user.subapplicationid) {
            reqparam = {
                pageno: '-1',
                query: {
                    status: 'Active',
                    area: {
                        $elemMatch: { "value": userdata?.user.subapplicationid }
                    }
                }
            }

        }
        else {
            reqparam = {
                pageno: '-1',
                query: { status: 'Active' }
            }

        }

        const action = "CATEGORY"

        const ResponseData = await PostApi(reqparam, action);
        setCategory(ResponseData.data)

    }




    async function ctgSearch(e, data) {
        if (e == "All") {
            setCategorySelect([])
            setSearchKey("")
        } else {
            const { checked } = e.target

            if (checked) {
                setSearchKey("")
                if (categorySelect.length < 0) {
                    setCategorySelect([data])
                }

                else {
                    setCategorySelect((prevState) => {
                        return [...prevState, data]
                    })
                }
            }
            else {
                setCategorySelect(categorySelect.filter((item) => item != data))
            }
        }


    }

    useEffect(() => {

        if (didMountRef.current) {

            const categorySelectCheck = categorySelect.filter(t => t != null);
            var tntId = JSON.parse(localStorage.getItem('tID'))
            const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
            console.log("tntId",tntId);
            

            const ctgbasedsearch = async () => {
                let reqparams1 = ''
                if (tntId === "2b7b1a31") {
                    if (userdata?.user.subapplicationid) {
                        reqparams1 = {
                            pageno: 1,
                            query: {
                                status: 'Active',
                                area: { $elemMatch: { "value": userdata?.user.subapplicationid } },
                                branch: { $elemMatch: { "value": userdata?.user.studentbranch } },
                                year: { $elemMatch: { "value": userdata?.user.year } }
                            },
                            optimize: true,
                            userid: '',
                            usertype: 'APP',
                            tenantid: ''
                        }

                    }
                    else {
                        reqparams1 = {
                            pageno: 1,
                            query: { status: 'Active', branch: { $elemMatch: { "value": userdata?.user.studentbranch } },
                            year: { $elemMatch: { "value": userdata?.user.year } } },
                            optimize: true,
                            userid: '',
                            usertype: 'APP',
                            tenantid: ''
                        }

                    }

                } else {

                    if (userdata?.user.subapplicationid) {
                        reqparams1 = {
                            pageno: 1,
                            query: { status: 'Active', area: { $elemMatch: { "value": userdata?.user.subapplicationid } } },
                            optimize: true,
                            userid: '',
                            usertype: 'APP',
                            tenantid: ''
                        }

                    }
                    else {
                        reqparams1 = {
                            pageno: 1,
                            query: { status: 'Active' },
                            optimize: true,
                            userid: '',
                            usertype: 'APP',
                            tenantid: ''
                        }

                    }
                }


                reqparams1.query.category = categorySelectCheck;
                // console.log("reqparams1", reqparams1);
                const response = await PostApi(reqparams1, 'ITPROGRAMS');
                // console.log("response", response.data);

                if (response.data.length > 0) {
                    setCourseList(response.data);
                    setTotalRecords(response.total)
                    setCurrentPage(1);
                } else {
                    setCourseList([]);
                    setTotalRecords(0)
                    setCurrentPage(1)
                }
                // setCourse(response.data)
                const updatedTypesWithCheck = updatedCategory.map((type) => ({
                    ...type,
                    check: categorySelect.some((categoryId) => categoryId === type.id),
                }));

                setUpdatedCategory(updatedTypesWithCheck)


            }

            if (categorySelectCheck.length != 0) {
                ctgbasedsearch()
                // console.log("serching")
            }
            else {

                if (searchKey == '') {
                    setCourseList(course)
                    setTotalRecords(count.total)
                    setCurrentPage(1)


                    const updatedTypesWithCheck = updatedCategory.map((type) => ({
                        ...type,
                        check: false,
                    }));

                    setUpdatedCategory(updatedTypesWithCheck)

                }
            }

        }
        else {
            didMountRef.current = true;
        }

    }, [categorySelect])

    //search function

    const searchBar = async (keySearch) => {

        setSearchKey(keySearch)

        var tntId = JSON.parse(localStorage.getItem('tID'))
        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        let reqparam = '';

        if (tntId === '2b7b1a31') {
            if (userdata?.user.subapplicationid) {
                reqparam = {
                    pageno: 1,
                    query: {
                        status: 'Active',
                        area: { $elemMatch: { "value": userdata?.user.subapplicationid } },
                        branch: { $elemMatch: { "value": userdata?.user.studentbranch } },
                        year: { $elemMatch: { "value": userdata?.user.year } }
                    },
                    usertype: 'APP',
                    tenantid: '',
                    optimize: true,
                    userid: ""

                }

            }
            else {
                reqparam = {
                    pageno: 1,
                    query: {
                        status: 'Active',
                        branch: { $elemMatch: { "value": userdata?.user.studentbranch } },
                        year: { $elemMatch: { "value": userdata?.user.year } }
                    },
                    usertype: 'APP',
                    tenantid: '',
                    optimize: true,
                    userid: ""

                }

            }

        } else {
            if (userdata?.user.subapplicationid) {
                reqparam = {
                    pageno: 1,
                    query: {
                        status: 'Active',
                        area: { $elemMatch: { "value": userdata?.user.subapplicationid } }
                    },
                    usertype: 'APP',
                    tenantid: '',
                    optimize: true,
                    userid: ""

                }

            }
            else {
                reqparam = {
                    pageno: 1,
                    query: { status: 'Active' },
                    usertype: 'APP',
                    tenantid: '',
                    optimize: true,
                    userid: ""

                }

            }
        }
        reqparam.query.name = { $regex: keySearch, $options: "i" }
        const ResponseData = await PostApi(reqparam, "ITPROGRAMS");

        if (ResponseData?.data?.length > 0) {
            setCourseList(ResponseData?.data);
            setTotalRecords(ResponseData.total)
            if (tntId != '2b7b1a31') {
                setCategorySelect([])
            }

        } else {
            setCourseList([]);
            setTotalRecords(0)

        }

        setCurrentPage(1)
        const updatedTypesWithCheck = updatedCategory?.map((type) => ({
            ...type,
            check: false,
        }));

        setUpdatedCategory(updatedTypesWithCheck)
    }

    useEffect(() => {


        const newData = async () => {

            var tntId = JSON.parse(localStorage.getItem('tID'))
            const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
            let reqparam = ''
            if (tntId === '2b7b1a31') {
                if (userdata?.user.subapplicationid) {
                    reqparam = {
                        pageno: currentPage,
                        query: {
                            status: 'Active', area: { $elemMatch: { "value": userdata?.user.subapplicationid } }
                            , branch: { $elemMatch: { "value": userdata?.user.studentbranch } },
                            year: { $elemMatch: { "value": userdata?.user.year } }
                        },
                        usertype: 'APP',
                        tenantid: '',
                        optimize: true,
                        userid: ""

                    }

                }
                else {
                    reqparam = {
                        pageno: currentPage,
                        query: { status: 'Active', branch: { $elemMatch: { "value": userdata?.user.studentbranch } } ,
                        year: { $elemMatch: { "value": userdata?.user.year } }},
                        usertype: 'APP',
                        tenantid: '',
                        optimize: true,
                        userid: ""

                    }

                }

            } else {

                if (userdata?.user.subapplicationid) {
                    reqparam = {
                        pageno: currentPage,
                        query: { status: 'Active', area: { $elemMatch: { "value": userdata?.user.subapplicationid } } },
                        usertype: 'APP',
                        tenantid: '',
                        optimize: true,
                        userid: ""

                    }

                }
                else {
                    reqparam = {
                        pageno: currentPage,
                        query: { status: 'Active' },
                        usertype: 'APP',
                        tenantid: '',
                        optimize: true,
                        userid: ""

                    }

                }
            }

            const action = "ITPROGRAMS"
            if (categorySelect.length != 0) {
                reqparam.query.category = categorySelect;
            }

            else if (searchKey != "") {

                reqparam.query.name = { $regex: searchKey, $options: "i" }

            }

            const ResponseData = await PostApi(reqparam, action);
            setCourseList(ResponseData.data);
            setTotalRecords(ResponseData.total)


        }
        // console.log("didMountRef.current",didMountRef.current);
        if (didMountRef2.current) {
            newData();
        }
        else {
            didMountRef2.current = true;
        }





    }, [currentPage])

    return (

        <div className="main-wrapper course-page">

            {/* Header 2 */}
            {tenantId == "ffe21ec9" ? <Header3 /> : <HeaderTwo />}
            {/* Breadcroumb */}
            {/* <BreadcrumbBox title="Programs" /> */}

            <Styles>
                {/* Course Grid */}
                <section className="course-list-area ">
                    <Container>
                        <Row>
                            <Col lg="3" md="4" sm="5">
                                <CourseSidebar updatedCategory={updatedCategory}
                                    // setSearchData={setSearchData}
                                    searchBar={searchBar}
                                    ctgFn={ctgSearch}
                                />
                            </Col>
                            <Col lg="9" md="8" sm="7">
                                <div className="course-items2">
                                    <Row>
                                        {/* {console.log("actual data",categoryData)} */}
                                        <CourseItemList
                                            courseList={courseList}
                                            course={course}
                                            totalRecords={totalRecords}
                                            setCurrentPage={setCurrentPage}
                                            currentPage={currentPage}

                                        />
                                        {/* currentPage={currentPage} */}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>

            {/* Footer 2 */}
            {tenantId == "ffe21ec9" ? <Footer3 /> : <FooterTwo />}

        </div>
    )
}


export default CourseList